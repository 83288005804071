import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby'
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';

import makeKey from '../tools/makeKey';
import media from '../tools/mediaQueries';
import Layout from '../layouts'
import Hero from '../components/Hero';

const About = ({ data }) => {
  const { history } = data.history.edges[0].node;
  const { sidebar } = data.sidebar.edges[0].node;
  const { lists } = data.lists.edges[0].node;

  return (
    <Layout>
    <Grid>
      <Helmet title="STERN & STERN - About us" />
      <Row style={{ background: '#fff' }}>
        <Hero image={data.hero.childImageSharp.fluid} title="about us" />
        <History xs={12} sm={8}>
          {history.map((item, i) => {
            const id = makeKey(item.header, i);
            return (
              <div key={id}>
                <h5>{item.header}</h5>
                {item.paragraphs.map((paragraph, index) => {
                  const newId = makeKey(paragraph, index);
                  return <p key={newId}>{paragraph}</p>;
                })}
              </div>
            );
          })}
        </History>
        <Sidebar xs={12} sm={4}>
          <Img fluid={data.boss.childImageSharp.fluid} imgStyle={{ objectPosition: '0 0' }} style={{ width: '135px' }} />
          <h6>
            Kenneth D. Stern, Esq. <br />Senior Partner
          </h6>
          {sidebar.map((item, i) => {
            const id = makeKey(item.header, i);
            return (
              <div key={id}>
                <h6>{item.header}</h6>
                <p>{item.paragraph}</p>
              </div>
            );
          })}
          {lists.map((item, i) => {
            const id = makeKey(item.header, i);
            return (
              <div key={id}>
                <h6>{item.header}</h6>
                <ul>
                  {item.paragraphs.map((paragraph, index) => {
                    const newId = makeKey(paragraph, index);
                    return <li key={newId}>{paragraph}</li>;
                  })}
                </ul>
              </div>
            );
          })}
        </Sidebar>
      </Row>
    </Grid>
    </Layout>
  );
};

export const query = graphql`
  query AboutPage {
    history: allAboutJson {
      edges {
        node {
          history {
            header
            paragraphs
          }
        }
      }
    }
    sidebar: allAboutJson {
      edges {
        node {
          sidebar {
            header
            paragraph
          }
        }
      }
    }
    lists: allAboutJson {
      edges {
        node {
          lists {
            header
            paragraphs
          }
        }
      }
    }
    hero: file(relativePath: {eq: "about_hero.jpg"}){
      childImageSharp{
        fluid(maxWidth: 1200){
          ...GatsbyImageSharpFluid
        }
      }
    }
    boss: file(relativePath: {eq: "pic-1.png"}){
      childImageSharp{
        fluid(maxWidth: 135){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

About.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default About;

const History = styled(Col)`
  font-family: 'Montserrat';

  h5 {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 22px;
    color: #4b721d;
    text-transform: uppercase;

    ${media.sm`
      font-size: 18px;
      line-height: 20px;
    `};
  }

  p {
    font-size: 16px;
    line-height: 20px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #000;
    text-align: justify;

    ${media.sm`
      font-size: 18px;
      line-height: 22px;
    `};
  }
`;

const Sidebar = styled(Col)`
  font-family: 'Montserrat';
  font-weight: 500;

  h6 {
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #4b721d;
    margin-bottom: 6px;
  }

  p {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  ul {
    font-size: 13px;
    line-height: 16px;
    margin: 0;
    padding: 0 1.5rem;
    margin-bottom: 20px;
  }
`;
